import React, { createContext, useState } from "react";
// import axios from 'axios'
import PropTypes from "prop-types";

import api from "./Api";

const ReturnStore = createContext(null);

const ReturnProvider = ({ children }) => {
  const INIT_STATE = {
    senderName: null,
    senderMobile: null,
    senderMobile2: null,
    senderAddress: null,
    senderAddressDetail: null,
    receiverName: null,
    receiverMobile: null,
    receiverMobile2: null,
    receiverAddress: null,
    receiverAddressDetail: null,
    memoFromCustomer: null,
    paymentMethod: null,
    productName: null,
    productPrice: null,
    productCategory: null,
    returnDeliveryId: null,
    token: null,
    frontdoorPasswordByClient: null,
  };

  const [info, setInfo] = useState(INIT_STATE);

  const fetchReturnData = async ({ bookId, mobile } = {}) => {
    const delivery = await api.get(`/deliveries/${bookId}/return`, {
      receiverMobile: mobile,
    });

    const etc4Obj = JSON.parse(delivery.delivery.etc4) || {};

    const {
      receiverName: senderName,
      receiverMobile: senderMobile,
      recieverMobile2: senderMobile2,
      receiverAddress: senderAddress,
      receiverAddressDetail: senderAddressDetail,

      senderName: receiverName,
      senderMobile: receiverMobile,
      senderMobile2: receiverMobile2,
      senderAddress: receiverAddress,
      senderAddressDetail: receiverAddressDetail,

      memoFromCustomer,
      paymentMethod,
      productName,
      productPrice,
      productCategory,
    } = delivery.delivery;

    setInfo({
      ...info,
      senderName,
      senderMobile,
      senderMobile2,
      senderAddress,
      senderAddressDetail,
      receiverName,
      receiverMobile,
      receiverMobile2,
      receiverAddress,
      receiverAddressDetail,
      memoFromCustomer,
      paymentMethod,
      productName,
      productPrice,
      productCategory,
      returnDeliveryId: bookId,
      frontdoorPasswordByClient: etc4Obj.frontdoorPasswordByClient,
    });

    return delivery;

    // return axios.get(`${process.env.REACT_APP_API_HOST_OLD}/api/deliveries/${bookId}/return`, {
    //   params: {
    //     receiverMobile: mobile
    //   }
    // })
    //   .then(response => {
    //     const {
    //       receiverName: senderName,
    //       receiverMobile: senderMobile,
    //       recieverMobile2: senderMobile2,
    //       receiverAddress: senderAddress,
    //       receiverAddressDetail: senderAddressDetail,

    //       senderName: receiverName,
    //       senderMobile: receiverMobile,
    //       senderMobile2: receiverMobile2,
    //       senderAddress: receiverAddress,
    //       senderAddressDetail: receiverAddressDetail,

    //       memoFromCustomer,
    //       paymentMethod,
    //       productName,
    //       productPrice,
    //       productCategory
    //     } = response.data.delivery

    //     const token = response.data.token

    //     setInfo({
    //       ...info,
    //       senderName,
    //       senderMobile,
    //       senderMobile2,
    //       senderAddress,
    //       senderAddressDetail,
    //       receiverName,
    //       receiverMobile,
    //       receiverMobile2,
    //       receiverAddress,
    //       receiverAddressDetail,
    //       memoFromCustomer,
    //       paymentMethod,
    //       productName,
    //       productPrice,
    //       productCategory,
    //       returnDeliveryId: bookId,
    //       token
    //     })

    //     return response.data
    //   })
  };

  const postReturnRequest = async (bookId, data) => {
    const response = await api.post(`/deliveries/${bookId}/return`, data);

    setInfo(INIT_STATE);

    return response;

    // return axios.post(`${process.env.REACT_APP_API_HOST_OLD}/api/deliveries/return`, {
    //   senderName,
    //   senderMobile,
    //   senderAddress,
    //   senderAddressDetail,

    //   receiverName: info.receiverName,
    //   receiverMobile: info.receiverMobile,
    //   receiverAddress: info.receiverAddress,
    //   receiverAddressDetail: info.receiverAddressDetail,
    //   memoFromCustomer,
    //   paymentMethod: info.paymentMethod,
    //   productName: info.productName,
    //   productPrice: info.productPrice,
    //   productCategory: info.productCategory,
    //   returnDeliveryId: info.returnDeliveryId
    // }, {
    //   headers: {'x-access-token': info.token}
    // })
    //   .then((response) => {
    //     setInfo(INIT_STATE)

    //     return response.data
    //   })
  };

  return (
    <ReturnStore.Provider value={{ fetchReturnData, postReturnRequest, info }}>
      {children}
    </ReturnStore.Provider>
  );
};

ReturnProvider.propTypes = {
  children: PropTypes.node,
};

export { ReturnStore, ReturnProvider };
