import React, { useState, useContext, useEffect } from "react";
import { css } from "@emotion/core";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import styled from "@emotion/styled";

import Nav from "../components/Nav";
import Ad from "../components/Ad";
import Footer from "../components/Footer";
import LoadingComponent from "../components/Loading";

import { ReturnStore } from "../stores/return";
import InputDelImage from "../images/input-del.png";

const BgTriangle = styled.div`
  width: 100%;
  height: 150px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 30px solid transparent;
    border-right: 100vw solid #202020;
    position: absolute;
    left: 0;
    top: 120px;
  }
`;
const InfoWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding: 0 16px;

  ${({ open }) =>
    open &&
    css`
       {
        display: none;
      }
    `}

  h1 {
    font-family: "Black Han Sans";
    font-size: 32px;
    margin: 0;
    letter-spacing: 0.05em;
  }

  p.primary {
    color: #ffffff;
    font-size: 16px;
    margin: 45px 0 25px;
  }

  p.p-gray {
    color: #999999;
    line-height: 1.6em;

    ::selection {
      background: #75ff6f;
      color: #ffffff;
    }
  }

  hr {
    border: 0.5px solid #333333;
    margin-top: 25px;
  }
`;
const StyledInfo = styled.span`
  font-size: 16px;
  color: #75ff6f;
`;
const StyledTitle = styled.div`
  margin-top: 15px;
  color: #ffffff;

  span {
    font-size: 12px;
    line-height: 1.6em;
  }

  h1 {
    font-family: "Black Han Sans", "Spoqa Han Sans", "Spoqa Han Sans JP",
      sans-serif;
    font-size: 32px;
    font-weight: normal;
    margin: 0;
    letter-spacing: 0.05em;
    position: relative;

    &:after {
      content: "2/2";
      font-family: "Spoqa Han Sans", "Spoqa Han Sans JP", sans-serif;
      font-size: 16px;
      position: absolute;
      top: 0;
      margin: 0 10px;
    }
  }
`;
const FormWrap = styled.form`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding: 0 16px;

  ${({ open }) =>
    open &&
    css`
       {
        display: none;
      }
    `}

  input {
    border: none;
    height: 50px;
    line-height: 50px;
    padding: 0 10px;
    font-size: 16px;
  }

  input:focus {
    outline: none;
  }

  input[type="text"],
  input[type="tel"] {
    width: 100%;
    background: #333333;
    border-bottom: 1px solid #999999;
    margin-bottom: 25px;
    color: #ffffff;

    :focus {
      border-bottom: 1px solid #75ff6f;
    }
  }

  input[type="text"].margin8 {
    margin-bottom: 8px;
  }

  p.p-gray {
    color: #999999;
    line-height: 1.6em;
    margin: 0 0 25px 0;

    ::selection {
      background: #75ff6f;
      color: #ffffff;
    }
  }

  label {
    display: block;
    color: #999999;
    margin-bottom: 8px;
  }

  textarea {
    overflow: auto;
    width: 100%;
    height: 200px;
    background: #333333;
    font-size: 16px;
    color: #fff;
    border: none;
    border: 1px solid #999999;
    padding: 10px;
    outline: none;
    resize: none;
    margin-bottom: 25px;
  }
`;
const SectionApplyButton = styled.button`
  border: none;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  font-size: 16px;
  background: #75ff6f;
  color: #202020;
  width: 100%;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 25px;
`;
const SectionApplyInputGroup = styled.div`
  position: relative;
`;

const SectionApplyInputIcon = styled.div`
  display: none;
  position: absolute;
  right: 9px;
  top: 9px;
  width: 32px;
  height: 32px;

  ${({ filled }) =>
    filled &&
    css`
       {
        display: block;
        background-image: url(${InputDelImage});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
      }
    `}
`;

const SectionAddressInputIcon = styled.div`
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/common/search.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
`;

const Inquiry = () => {
  const { info, postReturnRequest } = useContext(ReturnStore);

  const [open, setOpen] = useState(false);
  const [address, setAddress] = useState(info.senderAddress);

  const [loading, setLoading] = useState(false);

  const handleClickBurger = () => {
    setOpen(!open);
  };

  const [state, setState] = useState({
    senderName: info.senderName,
    senderMobile: info.senderMobile,
    senderMobile2: info.senderMobile2,
    senderAddressDetail:
      info.senderAddressDetail &&
      info.senderAddressDetail.indexOf(info.senderAddress) > -1
        ? info.senderAddressDetail.slice(info.senderAddress.length)
        : info.senderAddressDetail,
    memoFromCustomer: info.memoFromCustomer,
    frontdoorPasswordByClient: info.frontdoorPasswordByClient,
  });

  const handleChange = (e) => {
    let _state = { ...state };
    _state[e.target.name] = e.target.value;

    setState(_state);
  };

  const reset = (name) => {
    let _state = { ...state };
    _state[name] = "";

    setState(_state);
  };

  const { register, handleSubmit } = useForm();

  const handleSearchAddressClick = () => {
    new window.daum.Postcode({
      oncomplete: ({ userSelectedType, roadAddress, jibunAddress }) => {
        if (userSelectedType === "R") {
          setAddress(roadAddress);
        } else {
          setAddress(jibunAddress);
        }
      },
    }).open();
  };

  const onSubmit = (data) => {
    setLoading(true);

    postReturnRequest(info.returnDeliveryId, data)
      .then(() => {
        setLoading(false);

        alert("성공적으로 접수되었습니다.");
        history.push("/");
      })
      .catch((e) => {
        setLoading(false);

        alert(`반품 접수에 실패했습니다.\n${e.message}`);
      });
  };

  let history = useHistory();

  useEffect(() => {
    if (!info.returnDeliveryId) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {loading && <LoadingComponent />}
      <BgTriangle />
      <Nav open={open} />
      <InfoWrap open={open}>
        <StyledTitle>
          <span>착으로 오늘 도착 보장</span>
          <h1>반품신청</h1>
        </StyledTitle>

        <p className="primary">
          반품 신청을 위해 조회된 접수번호는 [
          <StyledInfo>{info.returnDeliveryId}</StyledInfo>] 입니다. <br />
          반품처는
          <StyledInfo>{" " + info.receiverName + ", "}</StyledInfo>
          <StyledInfo>
            {info.receiverAddressDetail
              ? info.receiverAddress + ", " + info.receiverAddressDetail + " "
              : info.receiverAddress + " "}
          </StyledInfo>
          입니다.
        </p>
        <p className="p-gray">
          아래 정보에서 반품하시는 고객님의 정보가 정확한지 확인해주세요. <br />
          * 전화통화가 가능한 연락처를 입력해주셔야 반품 수거가 가능합니다.{" "}
          <br />* 반품 물품을 보관해두시는 경우 아래 요청사항에 보관위치를
          정확하게 기재해주십시오.
        </p>

        <hr />
      </InfoWrap>

      <FormWrap onSubmit={handleSubmit(onSubmit)} open={open}>
        <label>반품자명</label>
        <SectionApplyInputGroup>
          <input
            type="text"
            placeholder="홍길동"
            onChange={handleChange}
            value={state.senderName}
            name="senderName"
            ref={register({ required: true })}
          />
          <SectionApplyInputIcon
            filled={state.senderName}
            onClick={() => reset("senderName")}
          />
        </SectionApplyInputGroup>

        <label>휴대폰번호1</label>
        <SectionApplyInputGroup>
          <input
            type="tel"
            placeholder="휴대폰번호를 입력해주세요."
            onChange={handleChange}
            value={state.senderMobile}
            name="senderMobile"
            ref={register({ required: true })}
          />
          <SectionApplyInputIcon
            filled={state.senderMobile}
            onClick={() => reset("senderMobile")}
          />
        </SectionApplyInputGroup>

        <label>휴대폰번호2</label>
        <SectionApplyInputGroup>
          <input
            type="tel"
            placeholder="휴대폰번호를 입력해주세요."
            onChange={handleChange}
            value={state.senderMobile2}
            name="senderMobile2"
            ref={register({ required: false })}
          />
          <SectionApplyInputIcon
            filled={state.senderMobile2}
            onClick={() => reset("senderMobile2")}
          />
        </SectionApplyInputGroup>

        <label>주소</label>
        <SectionApplyInputGroup>
          <input
            type="text"
            placeholder="주소"
            className="margin8"
            readOnly
            name="senderAddress"
            value={address}
            ref={register({ required: true })}
          />
          <SectionAddressInputIcon onClick={handleSearchAddressClick} />
          <SectionApplyInputGroup>
            <input
              type="text"
              placeholder="상세주소 (선택)"
              onChange={handleChange}
              name="senderAddressDetail"
              value={state.senderAddressDetail}
              ref={register({ required: false })}
            />
            <SectionApplyInputIcon
              filled={state.senderAddressDetail}
              onClick={() => reset("senderAddressDetail")}
            />
          </SectionApplyInputGroup>
        </SectionApplyInputGroup>

        <label>[필수]공동현관비밀번호(출입방법)</label>
        <SectionApplyInputGroup>
          <input
            type="text"
            placeholder="공동현관 비밀번호(출입방법)를 입력해주세요."
            onChange={handleChange}
            value={state.frontdoorPasswordByClient}
            name="frontdoorPasswordByClient"
            ref={register({ required: true })}
            maxLength={100}
          />
          <SectionApplyInputIcon
            filled={state.frontdoorPasswordByClient}
            onClick={() => reset("frontdoorPasswordByClient")}
          />
        </SectionApplyInputGroup>
        <label>요청사항</label>
        <textarea
          placeholder="요청사항을 입력해주세요."
          onChange={handleChange}
          name="memoFromCustomer"
          value={state.memoFromCustomer}
          ref={register({ required: false })}
        />
        <p className="p-gray">
          [개인정보 수집 및 이용 안내] <br />
          수집항목: 반품자명, 휴대폰번호, 주소, 요청사항 <br />
          수집및이용목적: 배송 서비스 제공 계약, 서비스 고객 본인 확인, 물품
          배송, 요금 결제, 화물사고 처리 및 배상 <br />
          보유 및 이용기간: 배송 서비스 제공 및 요금 결제/정산완료 후 5년(법정
          보존 기간)
        </p>
        <SectionApplyButton type="submit">
          반품 등록 및 개인정보 수집/이용동의
        </SectionApplyButton>
        <Ad open={open} />
      </FormWrap>
      <Footer open={open} />
    </div>
  );
};

export default Inquiry;
