import React from 'react';
import dotenv from "dotenv"
import ReactDOM from 'react-dom';
import AppRoutes from "./AppRoutes";

import "./App.css"

dotenv.config()

ReactDOM.render(<AppRoutes />, document.getElementById('root'));
