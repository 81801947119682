import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import Main from "./pages/index";
import Inquiry from "./pages/Inquiry";

import { ReturnProvider } from "./stores/return";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Root = () => {
  return (
    <ReturnProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/inquiry" component={Inquiry} />
          <Route path="*">
            <Redirect to="/"></Redirect>
          </Route>
        </Switch>
      </BrowserRouter>
    </ReturnProvider>
  );
};

export default Root;
