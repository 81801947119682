import React from "react";
import PropTypes from "prop-types";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const StyledFooter = styled.div`
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  z-index: 10;
  padding: 0 16px;

  ${({ open }) =>
    open &&
    css`
       {
        display: none;
      }
    `}

  li {
    line-height: 1.6em;
  }
`;

const StyledUl = styled.ul`
  color: #999999;
  font-size: 12px;
  list-style: none;
  padding: 10px 0 25px;
  border-top: 0.5px solid #333333;
`;

const Footer = ({ open }) => {
  return (
    <StyledFooter open={open}>
      <StyledUl>
        <li>착TM</li>
        <li>Copyright © 2024 그루핑 All rights reserved.</li>
      </StyledUl>
    </StyledFooter>
  );
};

Footer.propTypes = {
  open: PropTypes.bool,
};

export default Footer;
