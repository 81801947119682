import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  z-index: 100;
`;

const LoadingContent = styled.div`
  background-color: #fff;
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Image = styled.img`
  width: 70px;
`;

function Loading() {
  return (
    <Container>
      <LoadingContent>
        <Image src="/assets/images/common/loading.gif" alt="로딩중" />
      </LoadingContent>
    </Container>
  );
}

export default Loading;
