import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import styled from "@emotion/styled";

import BannerCu from "../images/banner-cu.png";
import BannerWingbling from "../images/doobalhero_wingbling.jpg";
import BannerPartner from "../images/banner-210909_1.png";
import BannerRider1 from "../images/banner-210909_2.png";
import BannerRider2 from "../images/banner-210909_3.png";
import BannerDooshop from "../images/banner-dooshop.png";
import BannerEzadmin from "../images/banner-ezadmin.jpg";
import BannerInstagram from "../images/banner-instagram.png";
import BannerGs from "../images/banner-gs.png";

const SliderWrap = styled.div`
  margin-left: -16px;
  margin-right: -16px;

  ${({ open }) => open && "display: none"}
`;

const BannerItem = styled.div``;

const BannerImage = styled.img`
  width: 100%;
`;

const Ad = memo(({ open }) => {
  const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const adList = [
    {
      image: BannerCu,
      url: "https://blog.naver.com/secondplanteam/222969089674",
      alt: "바로택배에서 가장 저렴한 운임으로 CU편의점택배를 이용하세요!",
    },
    {
      image: BannerGs,
      url: "https://blog.naver.com/secondplanteam/222969089674",
      alt: "GS25 편의점에서 초특가 택배비를 만나보세요.",
    },
  ];

  const shuffleArr = (arr) => {
    let currentIndex = arr.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = arr[currentIndex];
      arr[currentIndex] = arr[randomIndex];
      arr[randomIndex] = temporaryValue;
    }
    return arr;
  };

  return (
    <SliderWrap open={open}>
      <Slider {...sliderSettings}>
        {shuffleArr(adList).map((el, idx) => {
          return (
            <BannerItem key={idx}>
              {el.url ? (
                <Link
                  to={{
                    pathname: el.url,
                  }}
                  target="_blank"
                >
                  <BannerImage src={el.image} alt={el.alt} />
                </Link>
              ) : (
                <BannerImage src={el.image} alt={el.alt} />
              )}
            </BannerItem>
          );
        })}
      </Slider>
    </SliderWrap>
  );
});

Ad.propTypes = {
  open: PropTypes.bool,
};

export default Ad;
