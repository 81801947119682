import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styled from "@emotion/styled";
import { css } from "@emotion/core";

const NavWrap = styled.div`
  ${({ open }) =>
    open &&
    css`
      background-color: #202020;
      position: fixed;
      width: 100%;
      height: 100vh;
      z-index: 100;
    `};
`;
const StyledNav = styled.div`
  width: 100%;
  max-width: 799px;
  height: auto;
  margin: 0 auto;
  padding: 0 16px;
`;

const NavHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-item: center;
  padding-top: 15px;

  a {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  img {
    position: relative;
    display: block;
    width: 112px;
    height: 39px;
    z-index: 20;
  }
`;

const Nav = ({ open }) => {
  return (
    <NavWrap open={open}>
      <StyledNav>
        <NavHead>
          <Link to="/">
            <img src="/assets/images/common/logo-grouping.png" alt="착 로고" />
          </Link>
        </NavHead>
      </StyledNav>
    </NavWrap>
  );
};

Nav.propTypes = {
  open: PropTypes.bool,
};

export default Nav;
